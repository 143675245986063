'use client';

import * as React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import { ChevronDownIcon } from '@radix-ui/react-icons';

import { cn } from '@/utils/utils';

const Accordion = AccordionPrimitive.Root;

interface AccordionItemProps
    extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item> {
    isSection?: boolean;
}
const AccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    AccordionItemProps
>(({ className, isSection = false, ...props }, ref) => (
    <AccordionPrimitive.Item
        ref={ref}
        className={cn(
            isSection
                ? 'rounded-lg border border-border px-6 py-3 data-[state=open]:border-primary'
                : 'border-b',
            className
        )}
        {...props}
    />
));
AccordionItem.displayName = 'AccordionItem';

interface AccordionTriggerProps
    extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
    isSection?: boolean;
}

const AccordionTrigger = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Trigger>,
    AccordionTriggerProps
>(({ className, children, isSection, ...props }, ref) => (
    <AccordionPrimitive.Header className='flex'>
        <AccordionPrimitive.Trigger
            ref={ref}
            className={cn(
                'flex flex-1 items-center justify-between py-4 text-sm font-medium transition-all hover:underline [&[data-state=open]>svg]:rotate-180',
                className
            )}
            {...props}>
            {children}
            <ChevronDownIcon
                className={`${isSection ? 'h-7 w-10 rounded-lg border-primary bg-primary font-semibold text-white' : 'h-4 w-4 text-muted-foreground'} shrink-0 transition-transform duration-200`}
            />
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className='overflow-hidden text-sm data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down'
        {...props}>
        <div className={cn('pb-4 pt-0', className)}>{children}</div>
    </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
