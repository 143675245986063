'use client';
import {
    Tooltip as RadixTooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from '@/components/ui/tooltip';
import { cn } from '@/utils/utils';
import { ReactNode } from 'react';

export interface ITooltipProps {
    children: ReactNode;
    content: ReactNode;
    side?: 'top' | 'right' | 'bottom' | 'left';
    disabled?: boolean;
    className?: string;
}

const Tooltip = (props: ITooltipProps) => {
    const { children, content, disabled, side = 'top', className } = props;
    if (disabled) return <>{children}</>;
    return (
        <TooltipProvider delayDuration={0} skipDelayDuration={0}>
            <RadixTooltip>
                <TooltipTrigger className='max-w-fit' type='button'>
                    {children}
                </TooltipTrigger>
                <TooltipContent
                    className={cn('bg-slate-800 bg-opacity-90 dark:bg-slate-500', className)}
                    side={side}>
                    {content}
                </TooltipContent>
            </RadixTooltip>
        </TooltipProvider>
    );
};

export default Tooltip;
