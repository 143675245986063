const Routes = {
    DASHBOARD: '/dashboard',
    LOGIN: '/api/auth/login',
    LOGOUT: '/api/auth/logout',
    PROFILE: '/dashboard/profile',
    EXCHANGE: '/dashboard/exchange-settings',
    NOTIFICATION: '/dashboard/notification',
    STRATEGIES: '/dashboard/strategies',
    NEW_STRATEGY: '/dashboard/strategies/new',
    MARKETPLACE: '/dashboard/marketplace',
    BOT_RESULT: '/dashboard/result/{1}',
    CHARTS: '/dashboard/charts',
    BACKTEST_BOTS: '/dashboard/bots/backtest',
    NEW_BACKTEST_BOTS: '/dashboard/bots/backtest/new',
    BOTS_LIST: '/dashboard/bots/list',
    BOT_HISTORY: '/dashboard/bots/history',
    TRADE_BOTS: '/dashboard/bots/trade',
    NEW_TRADE_BOTS: '/dashboard/bots/trade/new',
    OPEN_ORDERS: '/dashboard/live/open-orders',
    TRADER_HISTORY: '/dashboard/bots/trader-history',
    MY_PACKAGE: '/dashboard/profile/my-package',
    MY_PACKAGE_GROUP_TRADING: '/dashboard/profile/my-package/group-trading',
    MY_PACKAGE_LIVE_TRADE: '/dashboard/profile/my-package/live-trade',
    MY_PACKAGE_BACKTESTING: '/dashboard/profile/my-package/backtesting',
    MY_PACKAGE_SIGNAL: '/dashboard/profile/my-package/signal',
    MY_WALLET: '/dashboard/profile/my-wallet',
    MY_WALLET_WALLET: '/dashboard/profile/my-wallet/wallet',
    MY_WALLET_EXCHANGE: '/dashboard/profile/my-wallet/exchange',
    MY_WALLET_BROKER: '/dashboard/profile/my-wallet/broker',
    MY_PROFILE: '/dashboard/profile/my-profile',
    MY_PROFILE_GENERAL: '/dashboard/profile/my-profile/general',
    MY_PROFILE_SECURITY: '/dashboard/profile/my-profile/security',
    MY_NOTIFICATION: '/dashboard/profile/my-notification',
    MY_AFFILIATE: '/dashboard/profile/my-affiliate',
} as const;

export default Routes;
